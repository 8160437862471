// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-cv-index-tsx": () => import("./../../../src/pages/cv/index.tsx" /* webpackChunkName: "component---src-pages-cv-index-tsx" */),
  "component---src-pages-exhibitions-index-tsx": () => import("./../../../src/pages/exhibitions/index.tsx" /* webpackChunkName: "component---src-pages-exhibitions-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publications-index-tsx": () => import("./../../../src/pages/publications/index.tsx" /* webpackChunkName: "component---src-pages-publications-index-tsx" */),
  "component---src-templates-exhibitions-post-tsx": () => import("./../../../src/templates/exhibitions/post.tsx" /* webpackChunkName: "component---src-templates-exhibitions-post-tsx" */),
  "component---src-templates-publications-post-tsx": () => import("./../../../src/templates/publications/post.tsx" /* webpackChunkName: "component---src-templates-publications-post-tsx" */),
  "component---src-templates-works-post-tsx": () => import("./../../../src/templates/works/post.tsx" /* webpackChunkName: "component---src-templates-works-post-tsx" */)
}

